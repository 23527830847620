/* LoginForm.css */
.full-page-centered {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100vh;
    text-align: center;
  }
  
  .form-field {
    margin-bottom: 20px; /* Adjust as needed */
  }
  
  .label {
    display: block;
    margin-bottom: 5px; /* Space between label and input field */
  }
  
  .input {
    width: 100%;
    padding: 8px;
    margin-bottom: 10px; /* Space between input field and next element */
  }
  
  .button {
    padding: 10px 20px; /* Adjust padding as needed */
    font-size: 16px; /* Adjust font-size as needed */
    font-weight: bold; /* Adjust font-weight as needed */
    color: white; /* This sets the text color to white */
    background-image: linear-gradient(40deg, #4730ce 0%, #1B65BD 45%, #11ACD4 70%, #35e9c5 100%);
    border: none; /* No border */
    border-radius: 20px; /* This makes the corners rounded */
    cursor: pointer; /* Changes the cursor on hover */
    transition: background-image 0.3s ease; /* Smooth transition for hover effect */
  }

  .button:hover {
    background-image: linear-gradient(40deg, #4730ce 0%, #1B65BD 35%, #11ACD4 60%, #35e9c5 100%);
  }