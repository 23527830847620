/* The following is from the MenthorQ less file
    We opted to use CSS instead of implementing a less
    compiler due to the possible complications of 
    ejecting.
*/
:root {
  --global-background: #010811;
  --font-family-inter: 'Inter', sans-serif;
  --button-primary-hover-gradient: linear-gradient(40deg, #4730ce 0%, #1B65BD 35%, #11ACD4 60%, #35e9c5 100%);
  --button-primary-active-gradient: linear-gradient(40deg, #4730ce 0%, #1B65BD 20%, #11ACD4 50%, #35e9c5 100%);
}

body {
  background-color: var(--global-background);
  font-family: var(--font-family-inter);
  color: white;
}


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
